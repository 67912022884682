import React, { useEffect } from "react";
import { coordinatesFormat } from "../../util";
import TestingCenter from "../../components/Testing/TestingCenter";
import DotLoader from "react-spinners/DotLoader";
import Map from "../../components/Testing/MapTesting";

const Searched = ({
  testingData,
  cityState,
  isLoading,
  setIsLoading,
  coordinatesArray,
  loadCenters,
  viewport,
  setViewport,
  setSelectedCenter,
  selectedCenter,
}) => {
  useEffect(() => {
    //once coordinatesArray is avaliable, we can change coordinates
    // format so we can put it in api link and send it over there
    const formatted_string_coordinates = coordinatesFormat(coordinatesArray);
    if (coordinatesArray[0] && coordinatesArray[1] !== undefined) {
      loadCenters(formatted_string_coordinates);
    }
  }, [coordinatesArray]);

  useEffect(() => {
    //if api testingcenter data loaded, setisloading to false and dont display spinner
    //.length only on array so saying once city state and both testing data avaliable, then display
    // our data.
    if (testingData.length && cityState.city) {
      testingData.sort((a, b) => a.distance - b.distance);
      setIsLoading(false);
      console.log("finished loading");
    }
  }, [testingData]);
  return (
    <div>
      {isLoading ? (
        <div className="spinner">
          <DotLoader size={150} color={"#6a149b"} />
        </div>
      ) : (
        <div className="searched">
          <div className="results-title-testing">
            <h1>
              We found {testingData.length} Results near {cityState.city},{" "}
              {cityState.state}:
            </h1>
          </div>
          <div className="list-map">
            <div className="searched-centers-testing">
              {testingData.map((center, index) => (
                <TestingCenter
                  center={center}
                  key={center.id}
                  index={index}
                  setSelectedCenter={setSelectedCenter}
                  setViewport={setViewport}
                  viewport={viewport}
                />
              ))}
            </div>
            <div className="map">
              <Map
                viewport={viewport}
                setViewport={setViewport}
                testingData={testingData}
                cityState={cityState}
                coordinatesArray={coordinatesArray}
                setSelectedCenter={setSelectedCenter}
                selectedCenter={selectedCenter}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Searched;
