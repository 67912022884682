import HomeSearch from "../../components/Testing/HomeTestingSearch";
const Home = ({
  getCoordinates,
  setCoordinatesArray,
  coordinatesArray,
  loadCenters,
  testingData,
  setTestingData,
  isLoading,
  setIsLoading,
}) => {
  return (
    <div className="home">
      <div className="home-search-full">
        <div className="home-title">
          <h1>Find a COVID testing center near you.</h1>
        </div>
        <div>
          <HomeSearch
            getCoordinates={getCoordinates}
            coordinatesArray={coordinatesArray}
            setCoordinatesArray={setCoordinatesArray}
            loadCenters={loadCenters}
            testingData={testingData}
            setTestingData={setTestingData}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
