const TestingCenter = ({ center, index, setSelectedCenter, setViewport, viewport }) => {
  const centerTitle = center.title.slice(22);

  const viewPortHandler = () => {
    setSelectedCenter(center);
    setViewport({
      ...viewport,
      latitude: center.position.lat - .05,
      longitude: center.position.lng + .19
    })
  }
  return (
    <div className="testing-card" onClick={viewPortHandler}>
      <h1> {index + 1 + "." + centerTitle}</h1>
      <div className="testing-info">
        <h4>{(center.distance / 1000).toFixed(1)} miles away</h4>
        <h3>
          {center.address.houseNumber} {center.address.street},{" "}
          {center.address.city}, {center.address.stateCode}{" "}
          {"phone" in center.contacts[0] &&
            "· " + center.contacts[0].phone[0].value.slice(2)}
        </h3>
      </div>
      <div className="testing-buttons">
        <button
          onClick={() =>
            window.open(
              `https://www.google.com/maps/search/?api=1&query=${center.position.lat},${center.position.lng}`,
              "_blank"
            )
          }
        >
          Get Directions
        </button>
        {"phone" in center.contacts[0] && (
          <button
            onClick={() => console.log(center.contacts[0].phone[0].value)}
          >
            <a href={`tel:${center.contacts[0].phone[0].value}`}>Call Site</a>
          </button>
        )}
      </div>
      <hr />
    </div>
  );
};
export default TestingCenter;
