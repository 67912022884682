import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import NJ from "../../img/nj.png"

const HomeVaccineSearch = ({
  getCoordinates,
  setIsLoading,
}) => {
  const history = useHistory();
  const [zipcode, setZipCode] = useState("");

  const inputHandler = (e) => {
    setZipCode(e.target.value);
  };

  const submitSearch = (e) => {
    e.preventDefault();
    if (zipcode.length === 5) {
      setIsLoading(true);
      console.log("loading-vaccine-center");
      getCoordinates(zipcode);
      history.push(`/results/${zipcode}`);
    }
  };
  return (
    <div className="home-search">
      <h2>Simply type your zipcode, and view centers in seconds.</h2>
      <form className="search">
        <input
          onChange={inputHandler}
          type="text"
          pattern="[0-9]*"
          maxLength="5"
          value={zipcode}
          placeholder="Enter zipcode"
        />
        <button onClick={submitSearch} type="submit">
          <FontAwesomeIcon
            icon={faSearch}
            size="2x"
            color="#6a149b"
          ></FontAwesomeIcon>
        </button>
      </form>
      <img src={NJ} alt=""/>
    </div>
  );
};
export default HomeVaccineSearch;
