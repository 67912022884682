const ErrorPage = ({error}) => {
    return(
        <div className="error">
            <h1>We did not find any centers for your zipcode:</h1>
            <h2>{error}</h2>
        </div>
    )
}


export default ErrorPage;