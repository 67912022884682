import React, { useState } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const Nav = ({
  setTestingData,
  setCityState,
  setIsLoading,
  setCoordinatesArray,
  getCoordinates,
  setSelectedCenter,
  setError
}) => {
  const history = useHistory();
  const location = useLocation();
  const pathId = location.pathname.slice(0, 16);
  const vaccinePath = location.pathname.slice(0, 8);
  const NavTitle =
    pathId === "/testing/results" || pathId === "/testing" || pathId === "/testing/error"
      ? "COVID-19 Testing"
      : "NJ Vaccine Locator";
  const zipcodePath =
    pathId === "/testing/results"
      ? location.pathname.split("/")[3]
      : location.pathname.split("/")[2];
  const [navZipCode, setNavZipCode] = useState("");
  const goHomeOnClick = () => {
    //clear out state of anything that may have been searched
    if (pathId === "/testing/results") {
      clearStates("/testing");
    } else if (vaccinePath === "/results") {
      clearStates("/");
    } else if (pathId === "/testing/error") {
      clearStates("/testing");
    }
  };

  const clearStates = (clearUrl) => {
    // function to help clear out state, takes out redundancy.
    if (pathId !== "/contact"){
      setTestingData([]);
      setCityState({});
      setCoordinatesArray([]);
      setSelectedCenter(null);
      setError("");
    }
    if (clearUrl) {
      history.push(clearUrl);
    }
  };
  const submitNavZipcode = (e) => {
    e.preventDefault();
    if (navZipCode.length === 5 && zipcodePath !== navZipCode) {
      setIsLoading(true);
      clearStates();
      console.log("loading nav bar search");
      getCoordinates(navZipCode);
      if (NavTitle === "COVID-19 Testing") {
        history.push(`/testing/results/${navZipCode}`);
      } else if (NavTitle === "NJ Vaccine Locator") {
        history.push(`/results/${navZipCode}`);
      }
    }
  };
  return (
    <header>
      <div className="logo">
        <p onClick={goHomeOnClick}>{NavTitle}</p>
      </div>
      <div className="input-form">
        {(pathId === "/testing/results" || vaccinePath === "/results" || pathId === "/error"  || pathId === "/testing/error") && (
          <div className="nav-input">
            <form className="nav-form">
              <input
                onChange={(e) => setNavZipCode(e.target.value)}
                type="text"
                defaultValue={zipcodePath}
                pattern="[0-9]*"
                maxLength="5"
                placeholder="Enter zipcode"
              />
              <button onClick={submitNavZipcode}>
                <FontAwesomeIcon icon={faSearch} size="lg"></FontAwesomeIcon>
              </button>
            </form>
          </div>
        )}
      </div>

      <nav>
        <ul>
          <li>
              <Link to="/" onClick={() => clearStates()}>NJ Vaccine Near Me</Link>
          </li>
          <li>
              <Link to="/testing" onClick={() => clearStates()}>Testing Near Me</Link>
          </li>
          <li>
              <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Nav;
