import React, { useEffect } from "react";
import ReactMapGL, { Marker, Popup } from "react-map-gl";
import Circle from "../../img/dry-clean.svg";
import CurrentLocation from "../../img/pin.svg";

const MapVaccine = ({
  viewport,
  setViewport,
  testingData,
  cityState,
  coordinatesArray,
  setSelectedCenter,
  selectedCenter
}) => {
  useEffect(() => {
    const listener = (e) => {
      if (e.key === "Escape") {
        setSelectedCenter(null);
      }
    };
    // adding event listener upon mount that listens for any key down
    window.addEventListener("keydown", listener);
  }, []);

  // const getIndexClass = (index) => {
  //   switch(index) {
  //     case 
  //   }
  // }
  return (
    <div>
      <ReactMapGL
        {...viewport}
        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        onViewportChange={(viewport) => {
          setViewport(viewport);
        }}
      >
        {testingData.map((center, index) => (
          <Marker
            key={center.id}
            latitude={Number(center.coordinates.latitude)}
            longitude={Number(center.coordinates.longitude)}
            index={index}
          >
            <div
              className={index > 8 ? "circle-high-index" : "circle-low-index"}
            >
              <img
                src={Circle}
                alt="circle"
                onClick={() => setSelectedCenter(center)}
              />
              <span onClick={() => setSelectedCenter(center)}>{index + 1}</span>
            </div>
          </Marker>
        ))}

        <Marker latitude={Number(coordinatesArray[0].latitude)} longitude={Number(coordinatesArray[0].longitude)}>
          <div className="current-location">
            <img src={CurrentLocation} alt="current location" />
            <h3>{cityState.city + ", " + cityState.state}</h3>
          </div>
        </Marker>

        {selectedCenter && (
          <Popup
            latitude={Number(selectedCenter.coordinates.latitude)}
            longitude={Number(selectedCenter.coordinates.longitude)}
            onClose={() => setSelectedCenter(null)}
          >
            <div className="popup">
              <h3>{selectedCenter.facilityName}</h3>
              <h5>
                {selectedCenter.facilityAddress}
              </h5>
            </div>
          </Popup>
        )}
      </ReactMapGL>
    </div>
  );
};

export default MapVaccine;
