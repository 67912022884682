import React, { useState, useEffect } from "react";
import Home from "./pages/Testing/HomeTesting";
import HomeVaccine from "./pages/Vaccine/HomeVaccine";
import Searched from "./pages/Testing/SearchedTesting";
import axios from "axios";
import "./styles/app.scss";
import Nav from "./components/shared/Nav";
import { Route, Switch } from "react-router-dom";
import ErrorPage from "./pages/ErrorPage";
import SearchedVaccine from "./pages/Vaccine/SearchedVaccine";
import ContactPage from "./pages/ContactPage";
import { useHistory, useLocation } from "react-router-dom";

const App = () => {
  const location = useLocation();
  const pathId = location.pathname.slice(0, 16);
  const vaccinePath = location.pathname.slice(0, 8);
  const [error, setError] = useState("");
  const history = useHistory();

  useEffect(() => {
    if (error !== "" && pathId === "/testing/results") {
      history.push("/testing/error");
    } else if (error !== "" && vaccinePath === "/results") {
      history.push("/error");
    }
  }, [error]);

  const [cityState, setCityState] = useState({
    city: "",
    state: "",
  });
  const [selectedCenter, setSelectedCenter] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [coordinatesArray, setCoordinatesArray] = useState([]);
  const [testingData, setTestingData] = useState([]);
  const [viewport, setViewport] = useState({
    latitude: 45.421,
    longitude: -75.6903,
    zoom: 11.2,
    height: "160vh",
    width: "150vw",
  });

  const getCoordinates = async (zipcode) => {
    const coordinates = await axios.get(
      `https://thezipcodes.com/api/v1/search?zipCode=${zipcode}&countryCode=US&apiKey=7d4d398b904272ff086fe785d8d1f5ce`
    );
    try {
      // hi
      const coordinatesData = coordinates.data.location;
      console.log(coordinatesData[0].latitude, "lat");
      console.log(coordinatesData[0].longitutde, "lat");
      console.log(coordinatesData[0].state, "s");
      console.log(coordinatesData[0].city, "c");

      setCoordinatesArray(coordinatesData);
      setViewport({
        ...viewport,
        latitude: coordinatesData[0].latitude,
        longitude: coordinatesData[0].longitude,
      });
      setCityState({
        city: coordinatesData[0].city,
        state: coordinatesData[0].state,
      });

      console.log("got city state and set viewport to zipcode entered by user");
    } catch (err) {
      console.log(err);
      setError(
        "Maybe you entered an invalid zipcode? Please try another zipcode."
      );
    }
  };

  const loadCenters = async (coordinates) => {
    const testingCall = await axios.get(
      `https://discover.search.hereapi.com/v1/discover?apikey=${process.env.REACT_APP_API_KEY}&q=Covid&at=${coordinates}&limit=50`
    );
    setTestingData(testingCall.data.items);
    console.log("Got testing center datas");
  };

  const loadVaccineCenters = async (latitude, longitude) => {
    console.log("Loading vaccine center");
    const vaccineCall = await axios.get(
      `https://njcovidvaccine.herokuapp.com/njvaccine/api/${latitude}/${longitude}`,
      {
        headers: {
          authorization: process.env.REACT_APP_AUTHORIZATION,
        },
      }
    );
    const vaccineData = vaccineCall.data.centers;
    const vaccineDataFilteredBy40MilesRadius = vaccineData.filter(
      (center) => center.distance <= 40
    );
    setTestingData(vaccineDataFilteredBy40MilesRadius);
    console.log("Got vaccine center data");
  };

  return (
    <div>
      <Switch>
        <Route path="/testing" exact>
          <Nav
            setTestingData={setTestingData}
            setCityState={setCityState}
            setIsLoading={setIsLoading}
            setCoordinatesArray={setCoordinatesArray}
            getCoordinates={getCoordinates}
            setSelectedCenter={setSelectedCenter}
            setError={setError}
          />
          <Home
            getCoordinates={getCoordinates}
            coordinatesArray={coordinatesArray}
            setCoordinatesArray={setCoordinatesArray}
            loadCenters={loadCenters}
            testingData={testingData}
            setTestingData={setTestingData}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        </Route>
        <Route path="/testing/results/:zip">
          <Nav
            setTestingData={setTestingData}
            setCityState={setCityState}
            setIsLoading={setIsLoading}
            setCoordinatesArray={setCoordinatesArray}
            getCoordinates={getCoordinates}
            setSelectedCenter={setSelectedCenter}
            setError={setError}
          />
          <Searched
            testingData={testingData}
            cityState={cityState}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            loadCenters={loadCenters}
            coordinatesArray={coordinatesArray}
            viewport={viewport}
            setViewport={setViewport}
            setSelectedCenter={setSelectedCenter}
            selectedCenter={selectedCenter}
          />
        </Route>
        <Route path="/" exact>
          <Nav
            setTestingData={setTestingData}
            setCityState={setCityState}
            setIsLoading={setIsLoading}
            setCoordinatesArray={setCoordinatesArray}
            getCoordinates={getCoordinates}
            setSelectedCenter={setSelectedCenter}
            setError={setError}
          />
          <HomeVaccine
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            coordinatesArray={coordinatesArray}
            setCoordinatesArray={setCoordinatesArray}
            getCoordinates={getCoordinates}
            setError={setError}
          />
        </Route>
        <Route path="/results/:zip">
          <Nav
            setTestingData={setTestingData}
            setCityState={setCityState}
            setIsLoading={setIsLoading}
            setCoordinatesArray={setCoordinatesArray}
            getCoordinates={getCoordinates}
            setSelectedCenter={setSelectedCenter}
            setError={setError}
          />
          <SearchedVaccine
            testingData={testingData}
            cityState={cityState}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            loadVaccineCenters={loadVaccineCenters}
            coordinatesArray={coordinatesArray}
            viewport={viewport}
            setViewport={setViewport}
            setSelectedCenter={setSelectedCenter}
            selectedCenter={selectedCenter}
            error={error}
            setError={setError}
          />
        </Route>
        <Route path="/contact" exact>
          <Nav
            setTestingData={setTestingData}
            setCityState={setCityState}
            setIsLoading={setIsLoading}
            setCoordinatesArray={setCoordinatesArray}
            getCoordinates={getCoordinates}
            setSelectedCenter={setSelectedCenter}
            setError={setError}
          />
          <ContactPage
            setTestingData={setTestingData}
            setCityState={setCityState}
            setIsLoading={setIsLoading}
            setCoordinatesArray={setCoordinatesArray}
            getCoordinates={getCoordinates}
          />
        </Route>
        <Route path="/testing/error" exact>
          <Nav
            setTestingData={setTestingData}
            setCityState={setCityState}
            setIsLoading={setIsLoading}
            setCoordinatesArray={setCoordinatesArray}
            getCoordinates={getCoordinates}
            setSelectedCenter={setSelectedCenter}
            setError={setError}
          />
          <ErrorPage
            error={error}
            setTestingData={setTestingData}
            setCityState={setCityState}
            setIsLoading={setIsLoading}
            setCoordinatesArray={setCoordinatesArray}
            getCoordinates={getCoordinates}
          />
        </Route>
        <Route path="/error" exact>
          <Nav
            setTestingData={setTestingData}
            setCityState={setCityState}
            setIsLoading={setIsLoading}
            setCoordinatesArray={setCoordinatesArray}
            getCoordinates={getCoordinates}
            setSelectedCenter={setSelectedCenter}
            setError={setError}
          />
          <ErrorPage
            error={error}
            setTestingData={setTestingData}
            setCityState={setCityState}
            setIsLoading={setIsLoading}
            setCoordinatesArray={setCoordinatesArray}
            getCoordinates={getCoordinates}
          />
        </Route>
        <Route path="/else">
          <Nav
            setTestingData={setTestingData}
            setCityState={setCityState}
            setIsLoading={setIsLoading}
            setCoordinatesArray={setCoordinatesArray}
            getCoordinates={getCoordinates}
            setSelectedCenter={setSelectedCenter}
            setError={setError}
          />
          <ErrorPage
            error={error}
            setTestingData={setTestingData}
            setCityState={setCityState}
            setIsLoading={setIsLoading}
            setCoordinatesArray={setCoordinatesArray}
            getCoordinates={getCoordinates}
          />
        </Route>
      </Switch>
    </div>
  );
};

export default App;
