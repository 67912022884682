import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const VaccineCenter = ({
  center,
  index,
  setSelectedCenter,
  setViewport,
  viewport,
}) => {
  const viewPortHandler = () => {
    setSelectedCenter(center);
    setViewport({
      ...viewport,
      latitude: center.coordinates.latitude - 0.05,
      longitude: center.coordinates.longitude + 0.19,
    });
  };
  // if (center.phoneNumber === 0) {
  //   center.phoneNumber = "";
  // }
  return (
    <div className="testing-card" onClick={viewPortHandler}>
      <h1> {index + 1 + ". " + center.facilityName}</h1>
      <div className="testing-info">
        {"distance" in center && (
          <h4>{center.distance.toFixed(1)} miles away</h4>
        )}
        <h3>
          {center.phoneNumber === 0
            ? center.facilityAddress
            : center.facilityAddress + "· " + center.phoneNumber}
        </h3>
      </div>
      <div className="age">
        <FontAwesomeIcon icon={faInfoCircle} size="lg"></FontAwesomeIcon>
        {center.minimumAge !== 0 ? <h4>{center.minimumAge} years old minimum</h4> : <h4>No data on minimum age provided.</h4>}
      </div>
      <div className="testing-buttons">
        {center.facilityWebsite !== 0 && (
          <button
            onClick={() => window.open(`${center.facilityWebsite}`, "_blank")}
          >
            Website
          </button>
        )}
        <button
          onClick={() =>
            window.open(
              `https://www.google.com/maps/search/?api=1&query=${center.coordinates.latitude},${center.coordinates.longitude}`,
              "_blank"
            )
          }
        >
          Directions
        </button>
        {center.phoneNumber !== 0 && (
          <button>
            <a href={`tel:${center.phoneNumber}`}>Call Site</a>
          </button>
        )}
      </div>
      <hr
        style={{
          color: "#f6f6f4",
          backgroundColor: "#f6f6f4",
          height: 0.5,
          borderColor: "#f6f6f4",
        }}
      />
    </div>
  );
};
export default VaccineCenter;
