import React, { useEffect } from "react";
import ReactMapGL, { Marker, Popup } from "react-map-gl";
import Circle from "../../img/dry-clean.svg";
import CurrentLocation from "../../img/pin.svg";

const Map = ({
  viewport,
  setViewport,
  testingData,
  cityState,
  coordinatesArray,
  setSelectedCenter,
  selectedCenter,
}) => {
  useEffect(() => {
    const listener = (e) => {
      if (e.key === "Escape") {
        setSelectedCenter(null);
      }
    };
    // adding event listener upon mount that listens for any key down
    window.addEventListener("keydown", listener);
  }, []);
  return (
    <div>
      <ReactMapGL
        {...viewport}
        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        onViewportChange={(viewport) => {
          setViewport(viewport);
        }}
      >
        {testingData.map((center, index) => (
          <Marker
            // key={center.id}
            latitude={center.position.lat}
            longitude={center.position.lng}
            index={index}
          >
            <div
              className={index > 8 ? "circle-high-index" : "circle-low-index"}
            >
              <img
                src={Circle}
                alt="circle"
                onClick={() => setSelectedCenter(center)}
              />
              <span onClick={() => setSelectedCenter(center)}>{index + 1}</span>
            </div>
          </Marker>
        ))}

        <Marker latitude={coordinatesArray[1]} longitude={coordinatesArray[0]}>
          <div className="current-location">
            <img src={CurrentLocation} alt="current location" />
            <h3>{cityState.city + ", " + cityState.state}</h3>
          </div>
        </Marker>

        {selectedCenter && (
          <Popup
            latitude={selectedCenter.position.lat}
            longitude={selectedCenter.position.lng}
            onClose={() => setSelectedCenter(null)}
          >
            <div className="popup">
              <h3>{selectedCenter.title}</h3>
              <h5>
                {selectedCenter.address.houseNumber}{" "}
                {selectedCenter.address.street}, {selectedCenter.address.city},{" "}
                {selectedCenter.address.stateCode}{" "}
              </h5>
            </div>
          </Popup>
        )}
      </ReactMapGL>
    </div>
  );
};

export default Map;
