import Nav from "../components/shared/Nav";
const ContactPage = () => {
    return(
        <div className="error">
            <Nav />
            <h1>Contact the developer at:</h1>
            <h2>Maor Mashiach</h2>
            <h3><a href="mailto:mmashiac@stevens.edu">mmashiac@stevens.edu</a></h3>
        </div>
    )
}


export default ContactPage;