import HomeVaccineSearch from "../../components/Vaccine/HomeVaccineSearch";
const Home = ({
  getCoordinates,
  setCoordinatesArray,
  coordinatesArray,
  isLoading,
  setIsLoading,
}) => {
  return (
    <div className="home">
      <div className="home-search-full-vaccine">
        <div className="home-title">
          <h1>Find NJ Vaccine Centers near you.</h1>
        </div>
        <div>
          <HomeVaccineSearch
            getCoordinates={getCoordinates}
            coordinatesArray={coordinatesArray}
            setCoordinatesArray={setCoordinatesArray}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
