import React, { useEffect } from "react";
import VaccineCenter from "../../components/Vaccine/VaccineCenter";
import DotLoader from "react-spinners/DotLoader";
import MapVaccine from "../../components/Vaccine/MapVaccine";
import { useHistory } from "react-router-dom";

const Searched = ({
  testingData,
  cityState,
  isLoading,
  setIsLoading,
  coordinatesArray,
  loadVaccineCenters,
  viewport,
  setViewport,
  setSelectedCenter,
  setError,
  selectedCenter,
}) => {
  const history = useHistory();

  useEffect(() => {
    //once coordinatesArray is avaliable, we can change coordinates
    // format so we can put it in api link and send it over there
    // const latitude = coordinatesArray[0].latitude;
    // const longitude = coordinatesArray[0].longitude;
    if (coordinatesArray.length) {
      // make sure we really got the latitude and longitude in our coordinatesArray
      loadVaccineCenters(coordinatesArray[0].latitude, coordinatesArray[0].longitude);
    }
  }, [coordinatesArray.length]);     

  useEffect(() => {
    //if api testingcenter data loaded, setisloading to false and dont display spinner
    //.length only on array so saying once city state and both testing data avaliable, then display
    // our data.
    if (testingData.length && cityState.city && cityState.state) {
      setIsLoading(false);
      console.log("finished loading");
    }
  }, [testingData]);

  useEffect(() => {
    if (cityState.state) {
      if (cityState.state !== "New Jersey" && cityState.state !== "New York") {
        setError(
          `We see that you are from ${cityState.city}, ${cityState.state}. We only support NJ zipcodes. Try 07677? `
        );
      }
      console.log(cityState.state);
    }
  }, [cityState]);

  return (
    <div>
      {isLoading ? (
        <div className="spinner">
          <DotLoader size={130} color={"#6a149b"} />
        </div>
      ) : (
        <div className="searched">
          <div className="results-title-vaccine">
            <h1>
              Found {testingData.length} Results within 40 miles of{" "}
              {cityState.city}, {cityState.state}
            </h1>
          </div>
          <div className="list-map">
            <div className="searched-centers-vaccine">
              <div className="results-description">
                <h3>
                  The following locations are avaliable to{" "}
                  <strong>certain</strong> individuals to receive the vaccine.
                  Have a look at{" "}
                  <a
                    href="https://covid19.nj.gov/pages/covid-19-vaccine-locations-for-eligible-recipients"
                    target="_blank"
                    rel="noreferrer"
                  >
                    NJ's Official COVID-19 Vaccine Page
                  </a>{" "}
                  to check if you qualify. Last updated:{" "}
                  <strong>{testingData[0].lastUpdated}</strong>
                </h3>
              </div>
              {testingData.map((center, index) => (
                <VaccineCenter
                  center={center}
                  key={center.id}
                  index={index}
                  setSelectedCenter={setSelectedCenter}
                  setViewport={setViewport}
                  viewport={viewport}
                />
              ))}
              <div className="source">
                <h3>
                  Source:
                  <a
                    href="https://covid19.nj.gov/pages/covid-19-vaccine-locations-for-eligible-recipients"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    NJ's Official COVID-19 Vaccine Page
                  </a>{" "}
                </h3>
              </div>
            </div>
            <div className="map">
              <MapVaccine
                viewport={viewport}
                setViewport={setViewport}
                testingData={testingData}
                cityState={cityState}
                coordinatesArray={coordinatesArray}
                setSelectedCenter={setSelectedCenter}
                selectedCenter={selectedCenter}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Searched;
